import { marked } from 'marked';
import React, { useEffect, useMemo } from 'react';
import { combineClasses, isEmpty } from '../../utils';
import styles from './MarkdownText.module.scss';

type Props = {
    text: string;
    // TODO describe settings type
    settings?: any;
    className?: string;
    linkClassName?: string;
    ellipsis?: boolean;
    placeholder?: React.ReactNode;
    onParsed?: (x: string) => void;
}
const MarkdownText: React.FC<Props> = ({ text, settings, className, linkClassName = "break-all", ellipsis, placeholder, onParsed }) => {

    const modifiedText = useMemo(() => {
        if (isEmpty(text)) return '';
        const lines = text.split('\n');

        // check all lines, if it's empty line, make a line break, if not, return the line
        return lines.map(line => {
            if (line.trim().length === 0)
                return line + '&nbsp;\n';

            return line;
        }).join('\n');
    }, [text]);

    useMemo(() => {
        marked.use(settings ?? {
            breaks: true,
            gfm: true,
            renderer: {
                paragraph(text: string) {
                    return `<p class="mb-0">${text}</p>`;
                },
                link(href: string | null, title: string | null, text: string) {
                    return `<a href="${href}" target="_blank" class="${linkClassName}">${text}</a>`;
                }
            }
        });
    }, [settings]);

    const textHtml = useMemo(() => marked.parse(modifiedText), [modifiedText]);
    useEffect(() => {
        onParsed?.(textHtml);
    }, [textHtml, onParsed]);

    if (isEmpty(textHtml))
        return <>{placeholder}</>;
    return <div
        dangerouslySetInnerHTML={{ __html: textHtml }}
        className={combineClasses(className, styles.markdown_inner)}
        data-ellipsis={ellipsis}
    ></div>;
}

export default MarkdownText;