import { observable, action } from 'mobx';
import Entity, { ISerializable } from '../Entity';
import BaseFilter from '../BaseFilter';
import Product from './Product';

export default class ProductCategory extends Entity implements ISerializable {
    constructor(c?: Partial<ProductCategory>) {
        //TODO makeObservable
        super();
        if (c) this.update(c);
    }

    @observable name: string = '';
    @observable description: string = '';
    @observable products: Product[] = [];

    toJson() {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            productIds: this.products?.map(x => x.id)
        }
    }

    @action update(c: Partial<ProductCategory>, allowUndefined?: boolean) {
        super.update(c, allowUndefined);
    }

    clone(changes?: Partial<ProductCategory>): ProductCategory {
        return new ProductCategory({
            ...this,
            products: this.products?.map(x => x.clone()),
            ...changes
        });
    }

    static fromJson(json: any): ProductCategory {
        return new ProductCategory({
            ...json,
            products: json.products ? json.products.map(Product.fromJson) : undefined
        });
    }
}

export class ProductCategoryFilter extends BaseFilter<ProductCategory> {
    constructor(filter?: Partial<ProductCategoryFilter>) {
        super();
        if (filter) this.update(filter);
    }
}